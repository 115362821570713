var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var INPUT_TYPE = exports.INPUT_TYPE = {
  mouse: "mouse",
  touch: "touch"
};
var ENLARGED_IMAGE_POSITION = exports.ENLARGED_IMAGE_POSITION = {
  over: "over",
  beside: "beside"
};
export default exports;