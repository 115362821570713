import _react3 from "react";
import _objectAssign3 from "object-assign";
import _LensTop3 from "./LensTop";
import _LensLeft3 from "./LensLeft";
import _LensRight3 from "./LensRight";
import _LensBottom3 from "./LensBottom";
import _Lens3 from "../../prop-types/Lens";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NegativeSpaceLens;
var _react = _react3;

var _react2 = _interopRequireDefault(_react);

var _objectAssign = _objectAssign3;

var _objectAssign2 = _interopRequireDefault(_objectAssign);

var _LensTop = _LensTop3;

var _LensTop2 = _interopRequireDefault(_LensTop);

var _LensLeft = _LensLeft3;

var _LensLeft2 = _interopRequireDefault(_LensLeft);

var _LensRight = _LensRight3;

var _LensRight2 = _interopRequireDefault(_LensRight);

var _LensBottom = _LensBottom3;

var _LensBottom2 = _interopRequireDefault(_LensBottom);

var _Lens = _Lens3;

var _Lens2 = _interopRequireDefault(_Lens);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

function NegativeSpaceLens(inputProps) {
  var userSpecifiedStyle = inputProps.style;
  var compositLensStyle = (0, _objectAssign2.default)({
    backgroundColor: "rgba(0,0,0,.4)"
  }, userSpecifiedStyle);
  var props = (0, _objectAssign2.default)({}, inputProps, {
    style: compositLensStyle
  });
  return _react2.default.createElement("div", null, _react2.default.createElement(_LensTop2.default, props), _react2.default.createElement(_LensLeft2.default, props), _react2.default.createElement(_LensRight2.default, props), _react2.default.createElement(_LensBottom2.default, props));
}

NegativeSpaceLens.propTypes = _Lens2.default;
export default exports;