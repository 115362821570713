import _imageRatio2 from "./imageRatio";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLensCursorOffset = getLensCursorOffset;
var _imageRatio = _imageRatio2;

function getLensCursorOffset(smallImage, largeImage, enlargedImageContainerDimensions) {
  var ratio = (0, _imageRatio.getLargeToSmallImageRatio)(smallImage, largeImage);
  return {
    x: getLensCursorOffsetDimension(enlargedImageContainerDimensions.width, ratio.x),
    y: getLensCursorOffsetDimension(enlargedImageContainerDimensions.height, ratio.y)
  };
}

function getLensCursorOffsetDimension(enlargedImageContainerDimension, ratio) {
  return Math.round(enlargedImageContainerDimension * ratio / 2);
}

export default exports;