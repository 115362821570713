import _propTypes3 from "prop-types";
import _Point3 from "./Point";
import _Image2 from "./Image";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _propTypes = _propTypes3;

var _propTypes2 = _interopRequireDefault(_propTypes);

var _Point = _Point3;

var _Point2 = _interopRequireDefault(_Point);

var _Image = _Image2;

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}

exports.default = {
  cursorOffset: _Point2.default,
  fadeDurationInMs: _propTypes2.default.number,
  isActive: _propTypes2.default.bool,
  isPositionOutside: _propTypes2.default.bool,
  position: _Point2.default,
  smallImage: _Image.SmallImageShape,
  style: _propTypes2.default.object
};
export default exports;